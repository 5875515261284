import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/shared/button';
import CardWithNumber from 'components/shared/card-with-number';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

const items = [
  {
    title: 'No PII',
    description: 'Access rich behavioral data with no personal information attached.',
  },
  {
    title: 'Scale',
    description: 'Unparalleled coverage of unified US consumer behavior.',
  },
  {
    title: 'Customizable',
    description:
      'Navigate our easy to use analytic platform or extract data to enhance in-house modeling capabilities.',
  },
  {
    title: 'Predictive',
    description:
      'Leverage Yobi’s predictive data to enhance internal modeling and evaluate company performance.',
  },
];

const BenefitsWithCTA = ({ theme }) => (
  <section className="pt-20 lg:pt-40">
    <Container className="grid grid-cols-1 text-center lg:text-left lg:grid-cols-12 gap-x-8">
      <div className="mb-12 grid-row-1 lg:mb-0 lg:col-start-1 lg:col-end-5">
        <Heading className="mb-8" tag="h2" size="lg" theme="black">
          Benefits of Yobi’s Platform
        </Heading>
        <Button to="/" theme="primary" size="md">
          Get Started
        </Button>
      </div>
      <div className="text-left lg:col-start-5 lg:col-end-13">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          {items.map(({ title, description }, index) => (
            <CardWithNumber
              title={title}
              description={description}
              index={index}
              theme={theme}
              key={index}
            />
          ))}
        </div>
      </div>
    </Container>
  </section>
);

BenefitsWithCTA.propTypes = {
  theme: PropTypes.oneOf(['white', 'gray']),
};
BenefitsWithCTA.defaultProps = {
  theme: 'white',
};
export default BenefitsWithCTA;
