import React from 'react';

import Benefits from 'components/pages/finance/benefits';
import BenefitsWithCTA from 'components/pages/finance/benefits-with-cta';
import Hero from 'components/pages/finance/hero';
import MainLayout from 'layouts/main';
import SeoMetadata from 'utils/seo-metadata';

const FinancePage = () => (
  <MainLayout pageMetadata={SeoMetadata.finance}>
    <Hero />
    <Benefits />
    <BenefitsWithCTA theme="gray" />
  </MainLayout>
);

export default FinancePage;
